import React from 'react';
import styles from './image.component.module.less';
import { ImageComponent as Image } from '@vaa-component-lib/component.atom.image';
import { TypographyComponent, TypographyVariant, TypographySize } from '@vaa-component-lib/component.atom.typography';
import classNames from 'classnames';

interface ImageProps {
    id?: string;
    url: string;
    alt: string;
    lazyEnabled?: boolean;
    width?: string;
    unit?: '%' | 'px';
    renditions?: {
        mobile?: string;
        tablet?: string;
        avif?: string;
        webp?: string;
    };
    linkURL?: string;
    linkTarget?: string;
    displayPopupTitle?: boolean;
    title?: string;
}

const ConditionalWrapper: React.FC<
    React.PropsWithChildren<{
        condition: boolean;
        wrapper: (children: React.ReactNode) => JSX.Element;
    }>
> = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

const ImageComponent = ({
    id,
    url,
    alt,
    linkURL,
    linkTarget = '_self',
    lazyEnabled,
    width = '100',
    unit = '%',
    renditions,
    title,
    displayPopupTitle = false,
}: ImageProps) => {
    return (
        <div id={id} className={styles.image}>
            <ConditionalWrapper
                condition={linkURL !== undefined && linkURL !== '' && linkURL !== null}
                wrapper={(children) => (
                    <a href={linkURL} target={linkTarget} rel="noopener noreferrer">
                        {children}
                    </a>
                )}
            >
                <figure>
                    <Image
                        src={url}
                        alt={alt}
                        isLazyLoaded={lazyEnabled}
                        width={width}
                        unit={unit}
                        isFullSourceSet={
                            renditions?.avif || renditions?.webp ? true : false
                        }
                        mobileSrc={renditions?.mobile && renditions?.mobile}
                        tabletSrc={renditions?.tablet && renditions?.tablet}
                    />
                    {title && (
                        <figcaption
                            className={classNames(styles.caption, {
                                [styles.popupTitle]: displayPopupTitle,
                            })}
                        >
                            <TypographyComponent
                                size={TypographySize.Sml}
                                element="span"
                                variant={
                                    linkURL ? TypographyVariant.Link : TypographyVariant.Body
                                }
                            >
                                {title}
                            </TypographyComponent>
                        </figcaption>
                    )}
                </figure>
            </ConditionalWrapper>
        </div>
    );
};

export default ImageComponent;