import { useState, useEffect } from 'react';
import globals from '@vaa-component-lib/shared.design-tokens/json/variables.json';

export enum ScreenSize {
  Mobile = 'MOBILE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP',
}

// A hook for ScrenSize
// Gets the inneerWidth of the current window and returns the matching Media Breakpoint
export const useMediaBreakpoint = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>();

  const breakpointMobile =
    globals.breakpoint.layout_sm_12_columns_fluid.breakpointMax; // 767
  const breakpointTablet =
    globals.breakpoint.layout_lg_12_column_fluid.breakpointMax; // 1279

  useEffect(() => {
    const setMediaBreakpoint = (e: any) => {
      const {
        target: { innerWidth: width },
      } = e;

      width <= breakpointMobile
        ? setScreenSize(ScreenSize.Mobile)
        : width <= breakpointTablet
        ? setScreenSize(ScreenSize.Tablet)
        : setScreenSize(ScreenSize.Desktop);
    };

    window.addEventListener('resize', setMediaBreakpoint);

    setMediaBreakpoint({ target: window });

    return () => {
      window.removeEventListener('resize', setMediaBreakpoint);
    };
  });

  return screenSize;
};
