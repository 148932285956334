import { TextLockupComponent as TextLockup } from '@vaa-component-lib/component.molecule.text-lockup';
import HTMLRenderer from 'src/components/html-renderer/html-renderer.component';
import { containsHTML } from 'src/utils/aem-utils/utils';
import styles from './text-lockup.component.module.less';
import classnames from 'classnames';
import { preprocessHTMLWithLinks } from '../../utils/component-utils/utils';

export default function TextLockupComponent(props: any) {
  const propsCopy = { ...props };

  Object.keys(propsCopy).forEach((key) => {
    if (typeof propsCopy[key] === 'string')
      propsCopy[key] = propsCopy[key]
        .replaceAll('&nbsp;', '')
        .replaceAll('\r', '')
        .replaceAll('\n', '')
        .trimRight();
  });

  if (props.hasOwnProperty('children') && containsHTML(props.children)) {
    if (typeof props.children === 'string') {
      propsCopy.children = preprocessHTMLWithLinks(props.children);
    }
    propsCopy.children = HTMLRenderer({ htmlString: propsCopy.children });
  }

  // Accordion content should not have padding
  if (props.hasOwnProperty('noPadding') && props.noPadding) {
    return <TextLockup {...propsCopy} />;
  }

  // Remove padding below text lockup, if specified
  const removeBottomPadding =
    props.hasOwnProperty('removeSpaceBelow') && props.removeSpaceBelow;

  return (
    <div
      className={classnames(styles['text-lockup'], {
        [styles['pb-none']]: removeBottomPadding,
      })}
    >
      <TextLockup {...propsCopy} />
    </div>
  );
}
