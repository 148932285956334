import {
  FocalPoint,
  HeroBannerComponent,
  TitleSize,
} from '@vaa-component-lib/component.molecule.hero-banner';
import styles from './hero-banner.component.module.less';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { subscribe, unsubscribe, SEARCH_PANEL_EVENT } from 'src/utils/events';
import { appendDomainToImageRenditions, preprocessHTMLWithLinks } from '../../utils/component-utils/utils';
import { convertToAbsoluteLinkUrl } from '../../utils';

type HeroBannerContent = {
  title: string;
  titleSize: TitleSize;
  text: string;
  buttons: {
    text: string;
    href: string;
  }[];
  focalPoint?: FocalPoint;
  desktopImage: {
    alt: string;
    url: string;
    renditions?: {
      mobile?: string;
      tablet?: string;
      avif?: string;
      webp?: string;
    };
  };
  mobileImage: {
    alt: string;
    url: string;
    renditions?: {
      mobile?: string;
      tablet?: string;
      avif?: string;
      webp?: string;
    };
  };
  campaignMessagePosition?: 'top' | 'bottom';
  campaignMessage?: {
    preText?: string;
    postText?: string;
    url: string;
    openInNewTab?: boolean;
    className?: string;
  };
};

enum VisibleContentEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface HeroBannerProps {
  componentId?: string;
  title: string;
  titleSize?: TitleSize;
  text: string;
  buttons: {
    text: string;
    href: string;
  }[];
  focalPoint?: FocalPoint;
  desktopImage: {
    alt: string;
    url: string;
    renditions?: {
      mobile?: string;
      tablet?: string;
      avif?: string;
      webp?: string;
    };
  };
  mobileImage: {
    alt: string;
    url: string;
    renditions?: {
      mobile?: string;
      tablet?: string;
      avif?: string;
      webp?: string;
    };
  };
  secondaryContent?: HeroBannerContent;
  campaignMessagePosition?: 'top' | 'bottom';
  campaignMessage?: {
    preText?: string;
    postText?: string;
    url: string;
    openInNewTab?: boolean;
    className?: string;
  }
}

const HeroBanner = ({
  componentId,
  title,
  titleSize = TitleSize.Lrg,
  text,
  buttons,
  focalPoint,
  desktopImage,
  mobileImage,
  secondaryContent,
  campaignMessagePosition,
  campaignMessage,
}: HeroBannerProps) => {
  const [visibleContent, setVisibleContent] = useState<VisibleContentEnum>(
    VisibleContentEnum.PRIMARY
  );
  const [isVisible, setIsVisible] = useState<boolean>(false); // TECH_DEBT: Having to add this to prevent SSR issues. Remove when fixed.
  const [height, setHeight] = useState<number>(0);
  const heroBannerContainerRef = useRef<HTMLDivElement>(null);
  const primaryBannerRef = useRef<HTMLInputElement>(null);
  const secondaryBannerRef = useRef<HTMLInputElement>(null);

  const updateButtonUrls = (buttons: { text: string; href: string }[] = []) => {
    return buttons?.map((button) => ({
      ...button,
      href: convertToAbsoluteLinkUrl(button?.href),
    }));
  };

  const updatedPrimaryButtons = updateButtonUrls(buttons || []);
  const updatedSecondaryButtons = secondaryContent
    ? updateButtonUrls(secondaryContent?.buttons || [])
    : [];
  const updatedPrimaryCampaignMessage = campaignMessage && campaignMessage.url ? {
    ...campaignMessage,
    url: convertToAbsoluteLinkUrl(campaignMessage?.url),
  } : undefined;
  const updatedSecondaryCampaignMessage = secondaryContent?.campaignMessage && secondaryContent.campaignMessage.url ? {
    ...secondaryContent?.campaignMessage,
    url: convertToAbsoluteLinkUrl(secondaryContent?.campaignMessage?.url),
  } : undefined;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    if (primaryBannerRef.current || secondaryBannerRef.current) {
      const primary = primaryBannerRef.current;
      const secondary = secondaryBannerRef.current;
      const primaryBannerSection = primary && primary.querySelector('section');
      const secondaryBannerSection =
        secondary && secondary.querySelector('section');
      const heights = [
        primaryBannerSection?.clientHeight,
        secondaryBannerSection?.clientHeight,
      ].filter((height) => height !== null && height !== undefined);
      const maxHeight = Math.max(...heights);

      setHeight(maxHeight);
    }
  }, [primaryBannerRef, secondaryBannerRef]);

  const handleContentChange = (content: VisibleContentEnum) => {
    if (
      !secondaryContent ||
      !secondaryContent?.desktopImage?.url ||
      !secondaryContent?.mobileImage?.url
    ) {
      console.error(
        'Hero Banner secondary content is missing required fields: ',
        secondaryContent
      );
      return;
    }
    setVisibleContent(content);
  };

  useEffect(() => {
    subscribe(SEARCH_PANEL_EVENT, (e: any) => {
      const bookingType = e.detail['bookingType'];
      const content =
        bookingType === 'HOLIDAY' ||
          bookingType === 'MULTICENTRE' ||
          bookingType === 'FLYDRIVE'
          ? VisibleContentEnum.SECONDARY
          : VisibleContentEnum.PRIMARY;

      handleContentChange(content);
    });
    return () => unsubscribe(SEARCH_PANEL_EVENT, () => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleContent]);

  const primaryProps = {
    title,
    text: preprocessHTMLWithLinks(text),
    titleSize,
    focalPoint,
    buttons: updatedPrimaryButtons,
    desktopImage: appendDomainToImageRenditions(desktopImage),
    mobileImage: appendDomainToImageRenditions(mobileImage),
    campaignMessagePosition,
    campaignMessage: updatedPrimaryCampaignMessage,
  };

  const secondaryProps = secondaryContent
    ? {
      title: secondaryContent.title || '',
      text: preprocessHTMLWithLinks(secondaryContent.text || ''),
      titleSize: secondaryContent.titleSize || TitleSize.Mdm,
      focalPoint: secondaryContent.focalPoint || FocalPoint.Center,
      buttons: updatedSecondaryButtons,
      desktopImage: appendDomainToImageRenditions(secondaryContent.desktopImage),
      mobileImage: appendDomainToImageRenditions(secondaryContent.mobileImage),
      campaignMessagePosition: secondaryContent.campaignMessagePosition,
      campaignMessage: updatedSecondaryCampaignMessage,
    }
    : null;

  return (
    <>
      {isVisible && (
        <div
          id={componentId}
          className={classNames(styles['hero-banner'])}
          ref={heroBannerContainerRef}
        >
          <div className={classNames(styles['hero-banner-item'], {
              [styles['fadeout']]: visibleContent === VisibleContentEnum.SECONDARY,
              [styles['fadein']]: visibleContent === VisibleContentEnum.PRIMARY,
              [styles['active']]: visibleContent === VisibleContentEnum.PRIMARY,
            })}
            ref={primaryBannerRef}
            style={{ minHeight: `${height}px` }}
          >
            <HeroBannerComponent {...primaryProps} />
          </div>
          {secondaryProps && (
            <div className={classNames(styles['hero-banner-item'], {
                [styles['fadeout']]: visibleContent === VisibleContentEnum.PRIMARY,
                [styles['fadein']]: visibleContent === VisibleContentEnum.SECONDARY,
                [styles['active']]: visibleContent === VisibleContentEnum.SECONDARY,
              })}
              ref={secondaryBannerRef}
              style={{ minHeight: `${height}px` }}
            >
              <HeroBannerComponent {...secondaryProps} />
            </div>
          )}
        </div>)}
    </>
  );
};

export default HeroBanner;
