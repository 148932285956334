import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CarouselComponent as Carousel } from '@vaa-component-lib/component.organism.carousel';
import {
  HotelCardComponent,
  HotelCardProps,
} from '@vaa-component-lib/component.molecule.hotel-card';
import style from './hotels-carousel.component.module.less';
import { CarouselConfiguration } from '@virgin-atlantic/component-lib';
import SkipLinksComponent from '../skip-links/skip-links.component';
import {
  ButtonComponent,
  ButtonSize,
  ButtonPriority,
} from '@vaa-component-lib/component.atom.button';
import {
  TypographyComponent,
  TypographySize,
  TypographyVariant,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import { ContainerComponent } from '@vaa-component-lib/component.layout.container';
import { appendDomainToImageRenditions, getHotelData, getLocationData } from 'src/utils/component-utils/utils';
import loadingImg from '../../assets/images/loading-hotel.png';
import { convertToAbsoluteLinkUrl } from '../../utils';

interface HotelsCarouselComponentProps {
  mimId: string;
  locationDescription?: string;
  hotels: HotelCardProps[];
  viewAllLink?: string;
  clientSide?: boolean;
  titleOverride?: string;
}

const loadingHotel: HotelCardProps = {
  loading: true,
  image: {
    url: loadingImg.src,
    alt: 'Hotel loading...',
  },
  name: 'Hotel name loading...',
  location: 'Location loading...',
  tripAdvisor: {
    rating: 0,
    reviews: 0,
  },
  virgin: {
    rating: 0,
  },
  details: [
    'Detail 1 loading...',
    'Detail 2 loading...',
    'Detail 3 loading...',
    'Detail 4 loading...',
  ],
  link: {
    url: '#',
    label: 'View details',
  },
};

export default function HotelsCarouselComponent({
  hotels = [],
  locationDescription = '',
  viewAllLink,
  clientSide = false,
  mimId,
  titleOverride,
}: HotelsCarouselComponentProps) {
  const loadingHotels = useMemo(
    () => Array.from({ length: 3 }, () => loadingHotel),
    []
  );
  const [hotelsData, setHotelsData] = useState<HotelCardProps[]>(hotels);
  const [locationName, setLocationName] = useState<string>(locationDescription);
  const transformHotelData = useCallback(
    (hotels: HotelCardProps[]) =>
      hotels.map((hotel) => ({
        ...hotel,
        image: appendDomainToImageRenditions(hotel.image),
      })),
    []
  );

  const content = useMemo(
    () => ({
      title: locationName ? `Hotels in ${locationName}` : 'Available hotels',
      skipLinks: locationName
        ? `Skip hotel cards for ${locationName}`
        : 'Skip hotel cards',
    }),
    [locationName]
  );

  let updatedViewAllLink = viewAllLink ? convertToAbsoluteLinkUrl(viewAllLink) : undefined;

  const fetchHotelsData = useCallback(
    async (locationId: string) => {
      const location = await getLocationData(locationId);
      if (location) {
        const hotelsObj = await getHotelData(location, true);
        if (!hotelsObj?.hotels) {
          return;
        }
        if (
          hotelsObj.hotels &&
          hotelsObj.hotels.length > 0 &&
          hotelsData.length === 0
        ) {
          setTimeout(() => {
            setHotelsData(transformHotelData(hotelsObj.hotels));
            setLocationName(hotelsObj.location);
          }, 1000);
        }
      }
    },
    [hotelsData.length, transformHotelData]
  );

  useEffect(() => {
    if (clientSide) {
      /// Load hotels carousel client side
      if (hotelsData.length === 0) {
        setHotelsData(transformHotelData(loadingHotels));
      }
      try {
        fetchHotelsData(mimId);
      } catch (e) {
        console.log('Error loading hotels carousel client side', e);
      }
    }
  }, [clientSide, hotelsData.length, loadingHotels, mimId, fetchHotelsData, transformHotelData]);

  if (!hotelsData || hotelsData.length === 0) {
    return null;
  }

  const handleFocus = (e: React.FocusEvent) => {
    const button = document.querySelector(
      '#view-all-hotels .button-component'
    ) as HTMLElement;
    if (button) {
      button.focus();
    }
  };

  return (
    <div
      className={style['hotels-carousel']}
      id="hotels-carousel"
      tabIndex={-1}
    >
      <SkipLinksComponent
        skipForwards={{
          targetId: 'view-all-hotels-focus',
          label: content.skipLinks,
        }}
        skipBackwards={{
          label: content.skipLinks,
        }}
      >
        <ContainerComponent as="div">
          <TypographyComponent
            variant={TypographyVariant.Heading}
            size={TypographySize.Lrg}
            weight={TypographyWeight.Regular}
            element="h2"
          >
            {titleOverride || content.title}
          </TypographyComponent>
        </ContainerComponent>
        <Carousel
          configuration={CarouselConfiguration.Heavy}
          controlsConfig={{
            arrows: hotelsData.length > 3,
            byScreen: true,
            byDrag: true,
            hideInactive: true,
            smallButtons: false,
          }}
        >
          {hotelsData.map((hotel: HotelCardProps, index: number) => (
            <HotelCardComponent key={`hotel--${index}`} {...hotel} />
          ))}
        </Carousel>
      </SkipLinksComponent>
      {viewAllLink && hotelsData.length > 3 && (
        <div id="view-all-hotels" className={style.viewAllHotels} tabIndex={-1}>
          <ButtonComponent
            text={
              hotelsData.length > 0
                ? `View all ${hotelsData.length} places to stay`
                : 'View all places to stay'
            }
            href={updatedViewAllLink}
            size={ButtonSize.Small}
            priority={ButtonPriority.Tertiary}
          />
          <div id="view-all-hotels-focus" tabIndex={-1} onFocus={handleFocus} />
        </div>
      )}
    </div>
  );
}
