import {
  BRANDS,
  ENVIRONMENTS,
  currentBrand,
  currentEnvironment,
  isPreviewMode,
} from './ssr-helper';

type Endpoints = {
  FEDERATED_DATA_API: { ABS: string; REL: string };
  LOCATIONS_API: string;
};

export const ENDPOINTS = {
  FEDERATED_DATA_API: {
    PATH: '/api/federated-data-api',
    PROD: 'https://www.virginatlantic.com/federated-data-api',
  },
  LOCATIONS_API: {
    PROD: 'https://www.virginatlantic.com/locations-public-api',
    TEST1:
      'http://vsolpublic-test1.webdev.vholsinternal.co.uk/locations-public-api',
  },
};

export const getEndpoint = (origin: string = ''): Endpoints => {
  let endpoints: Endpoints = {
    FEDERATED_DATA_API: { ABS: '', REL: '' },
    LOCATIONS_API: '',
  };
  switch (currentEnvironment) {
    case ENVIRONMENTS.LOCAL:
      switch (isPreviewMode) {
        case true:
          endpoints = {
            FEDERATED_DATA_API: {
              ABS: `${origin}/preview${ENDPOINTS.FEDERATED_DATA_API.PATH}`,
              REL: ENDPOINTS.FEDERATED_DATA_API.PATH,
            },
            LOCATIONS_API: `${origin}/preview${ENDPOINTS.LOCATIONS_API.PROD}`,
          };
          break;
        case false:
          endpoints = {
            FEDERATED_DATA_API: {
              ABS: `http:localhost:3000${ENDPOINTS.FEDERATED_DATA_API.PATH}`,
              REL: ENDPOINTS.FEDERATED_DATA_API.PATH,
            },
            LOCATIONS_API: `${origin}${ENDPOINTS.LOCATIONS_API.PROD}`,
          };
          break;
        default:
          break;
      }
      break;
    case ENVIRONMENTS.TEST1:
    case ENVIRONMENTS.TEST2:
    case ENVIRONMENTS.TEST3:
    case ENVIRONMENTS.TEST4:
    case ENVIRONMENTS.TEST5:
    case ENVIRONMENTS.UAT:
    case ENVIRONMENTS.PROD:
      switch (isPreviewMode) {
        case true:
          endpoints = {
            FEDERATED_DATA_API: {
              REL: `/preview${ENDPOINTS.FEDERATED_DATA_API.PATH}`,
              ABS: ENDPOINTS.FEDERATED_DATA_API.PROD,
            },
            LOCATIONS_API: ENDPOINTS.LOCATIONS_API.PROD,
          };
          break;
        case false:
          endpoints = {
            FEDERATED_DATA_API: {
              REL: ENDPOINTS.FEDERATED_DATA_API.PATH,
              ABS: ENDPOINTS.FEDERATED_DATA_API.PROD,
            },
            LOCATIONS_API: ENDPOINTS.LOCATIONS_API.PROD,
          };
          break;
        default:
          break;
      }
      break;
    default:
      endpoints = {
        FEDERATED_DATA_API: {
          REL: ENDPOINTS.FEDERATED_DATA_API.PATH,
          ABS: ENDPOINTS.FEDERATED_DATA_API.PROD,
        },
        LOCATIONS_API: ENDPOINTS.LOCATIONS_API.PROD,
      };
  }
  return endpoints;
};

export function getAEMBasepath() {
  let basePaths: any = {};
  switch (currentEnvironment) {
    case ENVIRONMENTS.TEST1:
    case ENVIRONMENTS.TEST2:
    case ENVIRONMENTS.TEST3:
    case ENVIRONMENTS.TEST4:
    case ENVIRONMENTS.TEST5:
      const envBrandPath = `${currentEnvironment.toLowerCase()}-${currentBrand.toLowerCase()}`;
      const envVivPath = `${currentEnvironment.toLowerCase()}-viv.webdev.vholsinternal.co.uk/bin/vaahome/AEMPreviewDataExporter`;
      basePaths.main = `https://${envBrandPath}home.webdev.vholsinternal.co.uk/`;
      basePaths.preview = `https://${envVivPath}?brandPath=`;
      basePaths.previewAsset = `https://${envVivPath}?assetPath=`;
      break;
    case ENVIRONMENTS.UAT:
      const AEM_PREVIEW_BASE_URL = 'https://author-virginatlantic-s5b.adobecqms.net/bin/vaahome/AEMPreviewDataExporter';
      switch (currentBrand) {
        case BRANDS.VHOLS:
          basePaths.main = 'https://stg-assets.virginholidays.co.uk/';
          basePaths.preview = `${AEM_PREVIEW_BASE_URL}?brandPath=`;
          basePaths.previewAsset = `${AEM_PREVIEW_BASE_URL}?assetPath=`;
          break;
        case BRANDS.VAA:
          basePaths.main = 'https://stg-vaabrowse.virginatlantic.com/';
          basePaths.preview = `${AEM_PREVIEW_BASE_URL}?brandPath=`;
          basePaths.previewAsset = `${AEM_PREVIEW_BASE_URL}?assetPath=`;
          break;
        default:
          break;
      }
      break;
    case ENVIRONMENTS.PROD:
      const VIV_PROD_BASE_URL = 'https://viv-prod.virginatlantic.com/bin/vaahome/AEMPreviewDataExporter';
      switch (currentBrand) {
        case BRANDS.VHOLS:
          basePaths.main = 'https://assets.virginholidays.co.uk/';
          basePaths.preview = `${VIV_PROD_BASE_URL}?brandPath=`;
          basePaths.previewAsset = `${VIV_PROD_BASE_URL}?assetPath=`;
          break;
        case BRANDS.VAA:
          basePaths.main = 'https://vaabrowse.virginatlantic.com/';
          basePaths.preview = `${VIV_PROD_BASE_URL}?brandPath=`;
          basePaths.previewAsset = `${VIV_PROD_BASE_URL}?assetPath=`;
          basePaths.publish = 'https://www.virginatlantic.com';
          break;
        default:
          break;
      }
      break;
    case ENVIRONMENTS.LOCAL:
      const LOCAL_BASE_URL = 'http://localhost:4502';
      switch (currentBrand) {
        case BRANDS.VHOLS:
        case BRANDS.VAA:
          basePaths.main = `${LOCAL_BASE_URL}/`;
          basePaths.preview = LOCAL_BASE_URL; // TODO: check this
          basePaths.previewAsset = `${LOCAL_BASE_URL}/bin/vaahome/AEMPreviewDataExporter?assetPath=`;
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
  // console.log({
  //   Environment: env,
  //   Brand: brand,
  //   basePaths,
  // });
  return basePaths;
}

export const getHostName = (isBookDomain: boolean = false) => {
  const env = currentEnvironment.toLowerCase();
  const brand = currentBrand.toLowerCase();
  let hostName = '';

  switch (brand) {
    case 'vaa':
      switch (env) {
        case 'test1':
        case 'test2':
        case 'test3':
        case 'test4':
          hostName = `https://vaa-${env}.virginatlantic.com`;
          break;
        case 'uat':
        case 'stg':
          switch (isBookDomain) {
            case true:
              hostName = 'https://book-stg.virginatlantic.com';
              break;
            case false:
              hostName = 'https://stg.virginatlantic.com';
              break;
            default:
              break;
          }
          break;
        case 'prod':
        case 'grn':
          switch (isBookDomain) {
            case true:
              hostName = 'https://book.virginatlantic.com';
              break;
            case false:
              hostName = 'https://www.virginatlantic.com';
              break;
            default:
              break;
          }
          break;
        case 'local':
          hostName = 'http://localhost:3000';
          break;
        default:
          break;
      }
      break;
    case 'vhols':
      switch (env) {
        case 'test1':
        case 'test2':
        case 'test3':
        case 'test4':
          hostName = `https://${brand}-${env}.webdev.vholsinternal.co.uk`;
          break;
        case 'uat':
        case 'stg':
          hostName = 'https://vhols-uat.webdev.vholsinternal.co.uk';
          break;
        case 'prod':
        case 'grn':
          hostName = 'https://www.virginholidays.co.uk';
          break;
        case 'local':
          hostName = 'http://localhost:3000';
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return hostName;
};