import React, { useState, useEffect, useRef } from 'react';
import styles from './video-component.component.module.less';
import classNames from 'classnames';
import {
  TypographyComponent,
  TypographySize,
  TypographyVariant,
  TypographyWeight,
} from '@vaa-component-lib/component.atom.typography';
import { getAEMBasepath } from '../../utils/basepaths';
import { isPreviewMode } from '../../utils/ssr-helper';

type posterImageProps = {
  url: string;
};

interface VideoComponentProps {
  componentId?: string;
  title: string;
  videoDescription?: string;
  posterImage: posterImageProps;
  videoUrl: string;
  videoStartTime?: string;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  componentId,
  title,
  videoDescription,
  posterImage,
  videoUrl,
  videoStartTime,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const imageBasePath = isPreviewMode ? getAEMBasepath().previewAsset : getAEMBasepath().main;
  const posterImageUrl = posterImage?.url
    ? imageBasePath + posterImage.url
    : '';

  const handlePlayVideo = () => {
    setIsPlaying(true);
    setTimeout(() => {
      iframeRef.current?.focus();
    }, 100);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handlePlayVideo();
    }
  };

  const extractVideoId = (url: string) => {
    try {
      const urlParams = new URLSearchParams(new URL(url).search);
      return urlParams.get('v');
    } catch (error) {
      return null;
    }
  };

  const videoUrlId = extractVideoId(videoUrl);
  const videoEmbedUrl = videoUrlId
    ? `https://www.youtube.com/embed/${videoUrlId}` +
      (videoStartTime ? `?start=${videoStartTime}` : '')
    : '';

  const svgPlayButton = (
    <div className={classNames(styles['svg-play-button'])}>
      <svg
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="70" height="70" rx="6" fill="#DA0530" />
        <path
          d="M45.5769 32.6572C47.5769 33.8119 47.5769 36.6986 45.5769 37.8533L32.3862 45.469C30.3862 46.6237 27.8862 45.1803 27.8862 42.8709L27.8862 27.6396C27.8862 25.3302 30.3862 23.8868 32.3862 25.0415L45.5769 32.6572Z"
          stroke="white"
          strokeWidth="3"
        />
      </svg>
    </div>
  );

  return (
    <div id={componentId} className={classNames(styles['video-component'])}>
      <div
        className={classNames(styles['video-wrapper'], {
          [styles['hidden']]: !isPlaying,
        })}
      >
        <iframe
          ref={iframeRef}
          className={styles['video-player']}
          src={videoEmbedUrl}
          title={title}
          allow="autoplay; encrypted-media"
          allowFullScreen
          tabIndex={0}
        />
      </div>

      {!isPlaying && (
        <div
          className={classNames(styles['video-poster'])}
          style={{
            backgroundImage: `url(${posterImageUrl})`,
          }}
        >
          <div
            className={styles['overlay']}
            onClick={handlePlayVideo}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="button"
            aria-label="Play video"
          >
            {svgPlayButton}

            <div className={styles['text-container']}>
              {title && (
                <TypographyComponent
                  element="h2"
                  variant={TypographyVariant.Heading}
                  size={TypographySize.Lrg}
                  weight={TypographyWeight.Medium}
                >
                  {title}
                </TypographyComponent>
              )}

              {videoDescription && (
                <TypographyComponent
                  element="p"
                  variant={TypographyVariant.Body}
                  size={TypographySize.Lrg}
                  weight={TypographyWeight.Regular}
                >
                  {videoDescription}
                </TypographyComponent>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoComponent;
