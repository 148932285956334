import { objectHasFields } from "src/utils/aem-utils/utils";
import { ValidationOutcomes, ValidationResult } from ".";
import isImagePropValid from "./image-property-validator";
import { convertToAbsoluteLinkUrl } from "src/utils";


const ValidateImageComponentProps = (props: any): ValidationResult => {
    const vr: ValidationResult = {
        result: ValidationOutcomes.UNKNOWN,
        messages: [],
        componentProps: null,
    };
    if (!props.hasOwnProperty('url') || !props.hasOwnProperty('alt')) {
        vr.result = ValidationOutcomes.FAILURE;
        vr.messages.push('Image is missing url or alt text');
        return vr;
    }
    const imageValidationResult = isImagePropValid(props);

    if (imageValidationResult.result === ValidationOutcomes.FAILURE) {
        vr.result = ValidationOutcomes.FAILURE;
        vr.messages = vr.messages.concat(imageValidationResult.messages);
        return vr;
    }

    if (imageValidationResult.result === ValidationOutcomes.WARNING) {
        vr.result = ValidationOutcomes.WARNING;
        vr.messages = vr.messages.concat(imageValidationResult.messages);
    }

    if (props.linkURL && props.hasOwnProperty('linkURL') && props.linkURL.length > 0) {
        const fullLinkURL = convertToAbsoluteLinkUrl(props.linkURL);
        if (fullLinkURL === null) {
            vr.result = ValidationOutcomes.FAILURE;
            vr.messages.push('Image has invalid linkURL');
            return vr;
        }
        imageValidationResult.componentProps.linkURL = fullLinkURL;
    }

    vr.result = ValidationOutcomes.SUCCESS;
    vr.componentProps = imageValidationResult.componentProps;

    return vr;

}

export default ValidateImageComponentProps;